import { ThemeOptions } from '@material-ui/core';

export const themeConfig: (darkMode: boolean) => ThemeOptions = (darkMode) => ({
  // typography: {
  //   fontSize: 10,
  //   // htmlFontSize: 12,
  // },
  palette: {
    type: darkMode ? 'dark' : 'light',
    primary: {
      main: '#0AAF60', // --primary
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 977,
      lg: 1173,
      xl: 1589,
    },
    step: 0,
  },
  overrides: {
    MuiButton: {
      root: { borderRadius: 12 },
    },
  },
});

import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

interface Props {
  className?: string;
}

export  const SvgFavoritesIcon: React.FC<Props> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 1L12.5921 6.43224L18.5595 7.21885L14.1942 11.3628L15.2901 17.2812L10 14.41L4.70993 17.2812L5.80584 11.3628L1.44049 7.21885L7.40787 6.43224L10 1Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </svg>
    </SvgIconContainer>
  );
};

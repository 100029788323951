import store from 'store/store';
import Nav, { NavOptions, ROUTES } from 'nav';
import i18n from 'i18n';
import { ITrade } from 'api/trades';
import { IBasis, ICategory, ICountry, ICulture, ICurrency, IPackaging, IShipping } from 'reducers/allReducer';
import { FormatEnum, LotType, TradeStatus } from 'constants/general';
import { DateTimeHelper } from 'core/helpers/dateTime.helper';
import { formatWithThousands } from 'utils/formatThousands';
import { LotBase } from 'models/lotBase';
import { SCOPE_LOT } from 'i18n/_single/Lot';
import { Company } from 'models/Company';

export type { ITrade };

export enum InitiatorTypeEnum {
  opened = 'opened',
  got = 'got',
}

type OptionType = 'my' | 'notMy' | 'lot' | 'trade' | 'current' | 'notCurrent';

export class Trade {
  private _store!: ReturnType<typeof store['getState']>;
  protected _lot!: LotBase;
  constructor (
    public $: ITrade,
    public companyId: string = '',
  ) {}

  get id() { return this.$.id; }

  goToPage(options?: NavOptions): void {
    const path = `${ROUTES.chat}?tradeId=${this.$.id}`;
    Nav.go(path, {...options, state: { trade: this } });
  }

  validUntil(format?: FormatEnum): string {
    return DateTimeHelper.format(this.$.valid_until, format);
  }
  get timeLeft(): string {
    return DateTimeHelper.timeLeft(this.$.valid_until);
  }

  get company(): Company {
    return new Company(this.store.all.companies.list[this.$.company_id]);
  }
  get basis(): IBasis {
    return this.store.all.basis.list[this.$.basis_id];
  }
  get category(): ICategory {
    return this.store.all.categories.list[this.culture.category_id];
  }
  get culture(): ICulture {
    const culture_id = this.$.culture_id || this.$.offer?.culture_id || this.$.request?.culture_id;
    return this.store.all.cultures.list[culture_id!];
  }
  get currency(): ICurrency {
    return this.store.all.currencies.list[this.$.currency_id];
  }
  get origin(): ICountry {
    const origin_id = this.$.origin_id;
    // const origin_id = this.$.origin_id || this.$.offer?.origin_id || this.$.request?.origin_id;
    return this.store.all.origins.list[origin_id!];
  }
  get packaging(): IPackaging {
    return this.store.all.packaging_options.list[this.$.packaging_id];
  }
  get shipping(): IShipping {
    return this.store.all.shipping_options.list[this.$.shipping_id];
  }
  get lot(): LotBase {
    return this._lot = this._lot || new LotBase(this.$.offer || this.$.request);
  }
  get currentLotTypeSide(): LotType {
    return this.isCurrentSide('lot')
      ? this.lot.type
      : this._tradeLotType;
  }
  get openType(): InitiatorTypeEnum {
    return this.lot.$.is_my_company
      ? InitiatorTypeEnum.got
      : InitiatorTypeEnum.opened;
  }
  get createdAt(): string {
    return DateTimeHelper.format(this.$.created_at);
  }
  get formatedPrice(): string {
    if (!('price' in this.$)) {
      return '';
    }
    return this.$.price
      ? `${formatWithThousands(this.$.price, 0)} ${this.currency.iso_code}`
      : i18n.t(`${SCOPE_LOT}$zeroPrice`);
  }

  formatedVolume(): string {
    return formatWithThousands(this.$.volume, 0);
  }

  getOwner(type: OptionType): string {
    return this.isLot(type)
      ? this.lot.$.owner
      : this.$.owner;
  }
  getCompany(type: OptionType): Company {
    const companyId: number = this.isLot(type)
      ? this.lot.$.company_id
      : this.$.company_id;
    return new Company(this.store.all.companies.list[companyId]);
  }
  getLotTypeSide(type: OptionType): LotType {
    return this.isLot(type)
      ? this.lot.type
      : this._tradeLotType;
  }
  isOfferType(type: OptionType): boolean {
    return this.getLotTypeSide(type) === LotType.offer;
  }
  isConfirmedBy(type: OptionType): boolean {
    return this.isLot(type)
      ? this.$.lot_confirmation
      : this.$.trade_confirmation;
  }
  isCurrentSide(type: OptionType): boolean {
    return this.isLot(type)
      ? this.lot.$.company_id === this.$.last_bid_company_id
      : this.$.company_id === this.$.last_bid_company_id;
  }
  isLot(type: OptionType): boolean {
    switch (type) {
      case 'my':
        return this.lot.isMy;
      case 'notMy':
        return !this.lot.isMy;
      case 'lot':
        return true;
      case 'trade':
        return false;
      case 'current':
        return this.$.last_bid_company_id === this.lot.$.company_id;
      case 'notCurrent':
        return this.$.last_bid_company_id === this.$.company_id;
      default:
        return false;
    }
  }


  quickParams(): string {
    return [
      formatWithThousands(this.$.volume),
      this.$.volume && i18n.t('т'),
      this.formatedPrice,
      this.basis.name,
    ].filter(Boolean).join(' ');
  }
  quickSecondParams(): string {
    return [
      this.packaging.name,
      i18n.t('в'),
      this.shipping.name,
    ].join(' ');
  }
  paramsWIthoutPrice(): string {
    return [
      formatWithThousands(this.$.volume),
      this.$.volume && i18n.t('т'),
      this.basis.name,
      this.packaging.name,
      i18n.t('в'),
      this.shipping.name,
    ].join(' ');
  }

  sideStatus(isMy = false): 'closed' | 'nothing' | 'hardOffer' | 'hardRequest' | 'softOffer' | 'softRequest' {
    if (this.isClosed && (isMy === this.isCurrentSide('my'))) {
      return 'closed';
    }
    if (this.isConfirmed) {
      return isMy === (this.getLotTypeSide('my') === LotType.offer) ? 'hardOffer' : 'hardRequest';
    }
    if (!this.$.price) {
      return 'nothing';
    }
    if (isMy === this.isCurrentSide('my')) {
      return this.currentLotTypeSide === LotType.offer
        ? this.isConfirming
          ? 'hardOffer'
          : 'softOffer'
        : this.isConfirming
          ? 'hardRequest'
          : 'softRequest';
    }
    return 'nothing';
  }


  get isConfirming(): boolean {
    return this.$.status === TradeStatus.confirming;
  }
  get isConfirmed(): boolean {
    return this.$.status === TradeStatus.confirmed;
  }
  get isValid(): boolean {
    return DateTimeHelper.isFuture(this.$.valid_until);
  }
  get isMy(): boolean {
    return this.$.is_my;
  }
  get isMyCompany(): boolean {
    return this.$.is_my_company;
  }
  get isEnded(): boolean {
    return [TradeStatus.confirmed, TradeStatus.closed].includes(this.$.status);
  }
  get isOpen(): boolean {
    return this.$.status === TradeStatus.open;
  }
  get isClosed(): boolean {
    return this.$.status === TradeStatus.closed;
  }
  get canMessage(): boolean {
    return [TradeStatus.confirming, TradeStatus.open, TradeStatus.confirmed].includes(this.$.status);
  }
  get canBid(): boolean {
    return this.isOpen || (!this.isClosed && !this.isConfirmedBy('my'));
  }
  get canConfirm(): boolean {
    if (!this.$.price && this.$.price <= 0) {
      return false;
    }
    return (
      this.isConfirming && this.isConfirmedBy('notMy')
    // ) || (
    //   this.$.status === TradeStatus.open && !this.currentLotSide.isMy
    );
  }

  private get _tradeLotType(): LotType {
    return this.lot.type === LotType.request ? LotType.offer : LotType.request;
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}

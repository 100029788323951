import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { classes } from 'utils';
import './style.sass';

const OPACITY_NONE = Symbol('None');

interface Params {
  className?: string;
  show?: boolean;
  opacity?: typeof OPACITY_NONE | boolean;
  children?: ReactNode;
  size?: number;
}

const LoaderForData: React.FC<Params> = ({ className, show = false, opacity = OPACITY_NONE, children, size = 80 }) => {
  if (show) {
    return (
      <div className={classes('LoaderForData-global', className)}>
        <CircularProgress className="LoaderForData" size={size} />
      </div>
    );
  }
  if (opacity === OPACITY_NONE) {
    return <>{children}</>;
  }
  return (
    <div className={classes('LoaderForData-opacity', { opacity }, className)}>
      <CircularProgress className="LoaderForData" size={size} />
      <div className="opacity-on">
        {children}
      </div>
    </div>
  );
};

export default LoaderForData;
